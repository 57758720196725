<template>
  <div style="width: 100%; height: 100%; position: relative">
    <div
      class="echarts-back"
      v-if="this.parentInfo.length > 1"
      @click="handleback"
    >
      返回
    </div>
    <div ref="echartsBox" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
// import countTo from 'vue-count-to';

import { getDataVSiteMap as fetchMapData } from '../../../api/home';

import OilSale from '@/assets/dataV/map_oil_sale.png';
import OilIn from '@/assets/dataV/map_oil_in.png';
import OilSum from '@/assets/dataV/map_oil_sum.png';
export default {
  // components: {
  //     countTo
  // },
  props: {
    // data: {
    //   type: Array,
    //   default() {
    //     return [];
    //   }
    // },
    custom: {
      type: Array,
      default() {
        return [];
      }
    },
    userCode: {
      type: Object,
      default() {
        return {
          adcode: this.$store.state.user.userInfo.code.slice(0, -3),
          name: this.$store.state.user.userInfo.area_name
        };
      }
    }
    // lableTitle: {
    //   type: Array,
    //   default() {
    //     return [];
    //   }
    // }
  },
  data() {
    return {
      data: [],
      lableTitle: [],
      timeTitle: ['2016'],
      geoJson: {
        features: []
      }
    };
  },
  computed: {
    level_name() {
      return this.level == 1
        ? 'province'
        : this.level == 2
        ? 'city'
        : 'district';
    },
    level() {
      return this.$store.state.user.userInfo.level;
    },
    areaName() {
      return this.$store.state.user.userInfo.area_name;
    },
    parentInfo() {
      return [
        {
          cityName: this.userCode.name,
          level: this.level_name,
          // level: 'china',
          code: this.userCode.adcode
        }
      ];
    }
  },
  async mounted() {
    // this.handleMount()
    await this.getMapInterfaceData();
    this.setTime();
    if (this.userCode.adcode) {
      if (this.level === '3') {
        let total = 0;
        this.data.forEach(item => {
          total += item.siteCount * 1;
        });
        this.data = [
          {
            value: total,
            name: this.areaName
          }
        ];
        this.getGeoJson(this.userCode.adcode.slice(0, 4) + '00');
      } else {
        this.getGeoJson(this.userCode.adcode);
      }
    } else {
      this.handleCustomNow(this.userCode.name);
    }
  },
  watch: {
    lableTitle: {
      handler(val, old) {
        if (val === old) {
          this.clearTime();
        }
      },
      immediate: false
    }
  },
  beforeDestroy() {
    this.clearTime();
  },
  methods: {
    // 每过30秒请求一次总量数据
    setTime() {
      this.clearTimeSet = setInterval(() => {
        this.getMapInterfaceData();
      }, 30 * 1000);
    },
    clearTime() {
      clearInterval(this.clearTimeSet);
    },
    handleback() {
      if (this.parentInfo.length === 1) {
        this.back = false;
        return;
      }

      if (this.parentInfo.length === 1) {
        this.back = false;
      }
      this.parentInfo.splice(this.parentInfo.length - 1, 1);
      const parent_level = this.parentInfo.length - 1;

      let _level =
        this.parentInfo[parent_level].level == 'city'
          ? 2
          : this.parentInfo[parent_level].level == 'district'
          ? 3
          : this.parentInfo[parent_level].level == 'province'
          ? 1
          : 4;
      this.getMapInterfaceData(
        this.parentInfo[parent_level].code + '000',
        _level
      );
      this.init(this.parentInfo[this.parentInfo.length - 1].code);
    },
    // 初始化地图
    init(data) {
      this.getGeoJson(data);
    },
    //获取高德地图的数据
    getGeoJson(adcode) {
      const that = this;
      window.AMapUI.loadUI(['geo/DistrictExplorer'], DistrictExplorer => {
        var districtExplorer = new DistrictExplorer();
        if (adcode) {
          districtExplorer.loadAreaNode(adcode, function(error, areaNode) {
            if (error) {
              console.error(error);
              return;
            }
            let Json = areaNode.getSubFeatures();
            if (Json.length > 0) {
              that.geoJson.features = Json;

              if (this.level === '3') {
                that.geoJson.features = that.geoJson.features.filter(
                  item => item.properties.adcode == that.userCode.adcode
                );
              }
            } else if (Json.length === 0) {
              // // that.geoJson.features = currentGeo;
              that.geoJson.features = that.geoJson.features.filter(
                item => item.properties.adcode == adcode
              );

              if (that.geoJson.features.length === 0) return;
            }

            that.handleCustom(adcode);
            that.getMapData();
          });
        }
      });
    },
    //自定义地图轮廓
    handleCustom(adcode) {
      if (this.custom.length != 0) {
        this.custom.map(item => {
          if (item.fatherCode == adcode) {
            for (let i = 0; i < item.children.length; i++) {
              for (let j = 0; j < this.geoJson.features.length; j++) {
                if (
                  item.children[i].name ==
                  this.geoJson.features[j].properties.name
                ) {
                  this.geoJson.features.splice(j, 1);
                  this.geoJson.features.push(
                    ...item.children[i].custom.features
                  );
                }
              }
              this.geoJson.features.push(...item.children[i].custom.features);
            }
          }
        });
      }
    },
    //自定义地图下钻
    handleCustomNow(name) {
      this.geoJson.features = [];
      for (let i = 0; i < this.custom.length; i++) {
        for (let j = 0; j < this.custom[i].children.length; j++) {
          if (this.custom[i].children[j].name == name) {
            this.geoJson.features.push(
              ...this.custom[i].children[j].custom.features
            );
          }
        }
      }
      this.getMapData();
    },

    //获取数据    拼接，获取近五年的数据
    getMapData() {
      let mapData = {},
        pointData = {},
        sum = {};
      for (let i = 0; i < this.timeTitle.length; i++) {
        mapData[this.timeTitle[i]] = [];
        pointData[this.timeTitle[i]] = [];
        sum[this.timeTitle[i]] = 0;
        for (let j = 0; j < this.geoJson.features.length; j++) {
          let value = null;
          for (let x = 0; x < this.data.length; x++) {
            if (
              this.data[x].name.includes(
                this.geoJson.features[j].properties.name
              )
            ) {
              value = parseInt(this.data[x].value);
              break;
            } else {
              value = 0;
            }
          }
          mapData[this.timeTitle[i]].push({
            name: this.geoJson.features[j].properties.name,
            value: value,
            level: this.geoJson.features[j].properties.level,
            cityCode: this.geoJson.features[j].properties.adcode
          });
          pointData[this.timeTitle[i]].push({
            name: this.geoJson.features[j].properties.name,
            value: [
              this.geoJson.features[j].properties.center[0],
              this.geoJson.features[j].properties.center[1],
              value
            ],
            cityCode: this.geoJson.features[j].properties.adcode
          });
          sum[this.timeTitle[i]] += value;
        }
        mapData[this.timeTitle[i]] = mapData[this.timeTitle[i]].sort(function(
          a,
          b
        ) {
          return b.value - a.value;
        });
      }
      this.initEchartMap(mapData, sum, pointData);
    },

    initEchartMap(mapData, sum, pointData) {
      if (this.parentInfo[0].code === 100000 && this.parentInfo.length === 1) {
        echarts.registerMap('china', this.geoJson); //注册
      } else {
        echarts.registerMap('map', this.geoJson); //注册
      }
      var options = {
        timeline: {
          show: false
        },
        baseOption: {
          // animation: true,
          // animationDuration: 1000,
          // animationEasing: 'cubicInOut',
          // animationDurationUpdate: 1000,
          // animationEasingUpdate: 'cubicInOut',
          // animationType: 'scale',
          // animationEasing: 'elasticOut',
          // animationDelay: function(idx) {
          //   return idx * 50;
          // },
          tooltip: {
            trigger: 'item'
          },
          geo: {
            map:
              this.parentInfo[0].code === 100000 && this.parentInfo.length === 1
                ? 'china'
                : 'map',
            zoom: 1,
            roam: false,
            label: {
              normal: {
                show: true,
                color: '#fff', //省份标签字体颜色
                formatter: p => {
                  switch (p.name) {
                    case '内蒙古自治区':
                      p.name = '内蒙古';
                      break;
                    case '西藏自治区':
                      p.name = '西藏';
                      break;
                    case '新疆维吾尔自治区':
                      p.name = '新疆';
                      break;
                    case '宁夏回族自治区':
                      p.name = '宁夏';
                      break;
                    case '广西壮族自治区':
                      p.name = '广西';
                      break;
                    case '香港特别行政区':
                      p.name = '香港';
                      break;
                    case '澳门特别行政区':
                      p.name = '澳门';
                      break;
                    default:
                      break;
                  }
                  return p.name;
                }
              },
              emphasis: {
                show: true,
                color: '#fff'
              }
            }
          }
        },
        options: []
      };

      this.timeTitle.forEach(item => {
        options.options.push({
          series: [
            {
              type: 'map',
              map:
                this.parentInfo[0].code === 100000 &&
                this.parentInfo.length === 1
                  ? 'china'
                  : 'map',
              roam: false,
              zoom: 1,
              avoidLabelOverlap: false,
              tooltip: {
                trigger: 'item',
                borderColor: '#FFFBF8',
                type: 'dashed',
                borderWidth: 1,
                borderRadius: 3,
                backgroundColor: 'rgba(97, 127, 221, .5)',
                formatter: p => {
                  let val = p.value;
                  if (p.name === '海南诸岛') return;
                  if (!val) {
                    val = 0;
                  }
                  return `<div style='
                              text-align: center;
                              padding: 0 19px;
                            '>${p.name}/${val}</div>`;
                }
              },
              label: {
                normal: {
                  show: true,
                  textStyle: {
                    color: '#fff',
                    textShadowColor: '#052e6f',
                    textShadowOffsetX: 0,
                    textShadowOffsetY: 5,
                    textShadowBlur: 9
                  }
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    color: '#fff',
                    textShadowColor: '#052e6f',
                    textShadowOffsetX: 0,
                    textShadowOffsetY: 5,
                    textShadowBlur: 9
                  }
                }
              },
              itemStyle: {
                borderColor: '#00BA93',
                borderWidth: 1,
                areaColor: {
                  type: 'linear',
                  x: 0,
                  y: 0.5,
                  x2: 0,
                  y2: 1,
                  // r: 0.8,
                  colorStops: [
                    {
                      offset: 0,
                      color: '#00354D' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#00354D' // 100% 处的颜色
                    }
                  ],
                  globalCoord: true // 缺省为 false
                },
                emphasis: {
                  // borderWidth: 0,
                  borderWidth: 2,
                  areaColor: {
                    type: 'linear',
                    x: 0.3,
                    y: 1,
                    x2: 0,
                    y2: 0,
                    // r: 0.8,
                    colorStops: [
                      {
                        offset: 0,
                        color: '#56372A' // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: '#EAA464' // 100% 处的颜色
                      }
                    ],
                    globalCoord: true // 缺省为 false
                  },
                  borderColor: '#FFC391'
                }
              },
              data: mapData[item],
              animationType: 'scale',
              animationEasing: 'elasticOut',
              animationDelay: function(idx) {
                return idx * 50;
              }
            },
            {
              name: '散点',
              type: 'effectScatter',
              coordinateSystem: 'geo',
              tooltip: {
                trigger: 'item',
                borderColor: '#FFFBF8',
                type: 'dashed',
                borderWidth: 1,
                borderRadius: 3,
                backgroundColor: 'rgba(97, 127, 221, .5)',
                formatter: p => {
                  let val = p.value;
                  if (p.name === '海南诸岛') return;
                  if (!val) {
                    val = 0;
                  }
                  return `<div style='
                              text-align: center;
                              padding: 0 19px
                            '>${p.name}/${val[2]}</div>`;
                }
              },
              hoverAnimation: true,
              itemStyle: {
                color: '#EAA464',
                shadowBlur: 10,
                shadowColor: '#333'
              },
              data: pointData[item],
              // symbolSize: 8,
              symbolSize: function(val) {
                let value = val[2];
                if (value <= 0) {
                  return 0;
                } else if (value > 0 && value <= 500) {
                  return 5;
                } else if (value > 500 && value <= 1000) {
                  return 10;
                } else if (value > 1000 && value <= 2000) {
                  return 15;
                }
                return 20;
              },
              showEffectOn: 'render' //加载完毕显示特效
            }
          ]
        });
      });
      let that = this;
      let mount = echarts.init(this.$refs.echartsBox);
      mount.setOption(options, true);
      mount.off('click');
      mount.on('click', params => {
        // console.log(params);
        if (!params.data) {
          return;
        } else if (params.data.cityCode) {
          //如果当前是最后一级，那就直接return
          if (
            that.parentInfo[that.parentInfo.length - 1].code ==
            params.data.cityCode
          ) {
            return;
          }
          let data = params.data;
          that.parentInfo.push({
            cityName: data.name,
            level: data.level,
            code: data.cityCode
          });

          // this.$emit('getAreaData', {
          //   areaCode: data.cityCode + '000',
          //   level: data.level == 'city' ? 2 : data.level == "district" ? 3 : data.level == "province" ? 1 : 4
          // });
          let _level =
            data.level == 'city'
              ? 2
              : data.level == 'district'
              ? 3
              : data.level == 'province'
              ? 1
              : 4;
          this.getMapInterfaceData(data.cityCode + '000', _level);
          that.getGeoJson(data.cityCode);
        } else {
          if (
            that.parentInfo[that.parentInfo.length - 1].name == params.data.name
          ) {
            return;
          }
          let data = params.data;
          that.parentInfo.push({
            cityName: data.name,
            level: data.level,
            code: data.cityCode
          });
          that.handleCustomNow(params.data.name);
        }
      });
    },
    async getMapInterfaceData(adcode, level) {
      const params = {
        areaCode: adcode || this.$store.state.user.userInfo.code,
        level: level || this.level
      };
      const result = await fetchMapData(params);
      if (result && result.code === 0) {
        console.log(result);
        if (level != 3) {
          result.data.list.forEach(item => {
            item.value = item.site_count;
            item.name = item.depart_name;
          });
          this.data = result.data.list;
        }

        this.lableTitle = [
          { title: '销售总量(L)', icon: OilSale, value: result.data.saleSum },
          { title: '进油总量(L)', icon: OilIn, value: result.data.inputSum },
          {
            title: '加油站实时总存储油量(L)',
            icon: OilSum,
            value: result.data.oilMargin
          }
        ];
      } else {
        this.data = [];
      }
    }
  }
};
</script>
<style>
.echarts-back {
  position: absolute;
  color: #7299f2;
  z-index: 1000;
  font-weight: 900;
  padding: 15px;
  cursor: pointer;
}

.label-box {
  position: absolute;
  right: 0;
  bottom: 0;
  /* text-align: right; */
}

.label-titles {
  font-weight: 400;
  color: #ededed;
  padding-right: 0px;
  margin-bottom: 18px;
  border: 1px solid;
  border-image: linear-gradient(90deg, #050511, #d56430) 1 1;
  padding: 10px 0 5px;
}

.label-titles > div {
  padding-right: 12px;
}

.label-titles > div:nth-child(1) {
  font-family: 'Microsoft YaHei';
  font-size: 12px;
  padding-bottom: 5px;
}

.label-titles > div:nth-child(2) {
  font-size: 20px;
  height: 28px;
  /* background: linear-gradient(90deg, #10253f33 0%, #e896a333 100%); */
  /* opacity: 0.2; */
  /* border-radius: 16px; */
  line-height: 26px;
  color: #f8a85e;
  width: auto;
  font-family: 'HuXiaoBo-NanShenTi2.0';
}

.label-titles:last-of-type > div:nth-child(2) {
  font-size: 22px;
  height: 32px;
  line-height: 30px;
  border-radius: 23px;
}
</style>
