var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"borderBoxNode",staticClass:"border-box"},[_c('svg',{staticClass:"border",attrs:{"width":_vm.width,"height":_vm.height}},[_c('defs',[_c('linearGradient',{attrs:{"id":"borderLineGradient","x1":"0%","x2":"100%","y1":"0%","y2":"0%","gradientTransform":"rotate(45)"}},[_c('stop',{attrs:{"offset":"5%","stop-color":"#21E7FF"}}),_c('stop',{attrs:{"offset":"95%","stop-color":"#2D77F3"}})],1)],1),_c('path',{attrs:{"d":`M 20,2 L 20, 2 ${_vm.width - 22}, 2 ${_vm.width - 12}, 2 ${_vm.width -
          2}, 12 ${_vm.width - 2}, ${_vm.height - 12}
        ${_vm.width - 12}, ${_vm.height - 2} 12, ${_vm.height - 2} 2, ${_vm.height -
          12} 2, 12 12, 2 Z
      `,"fill":"none","stroke":"url(#borderLineGradient)","stroke-width":"1px"}}),_c('defs',[_c('linearGradient',{attrs:{"id":"grad1","x1":"0%","y1":"0%","x2":"100%","y2":"0%"}},[_c('stop',{staticStyle:{"stop-color":"rgba(33, 231, 255, .5)","stop-opacity":"1"},attrs:{"offset":"0%"}}),_c('stop',{staticStyle:{"stop-color":"rgba(33, 231, 255, .1)","stop-opacity":"1"},attrs:{"offset":"100%"}})],1)],1)]),(_vm.ifPanel)?_c('svg',{staticClass:"border",attrs:{"width":_vm.width,"height":_vm.height}},[_c('defs',[_c('linearGradient',{attrs:{"id":"grad2","x1":"0%","y1":"0%","x2":"100%","y2":"0%"}},[_c('stop',{staticStyle:{"stop-color":"#21E7FF","stop-opacity":"1"},attrs:{"offset":"0%"}}),_c('stop',{staticStyle:{"stop-color":"#21E7FF","stop-opacity":"1"},attrs:{"offset":"50%"}}),_c('stop',{staticStyle:{"stop-color":"#2D77F3","stop-opacity":"1"},attrs:{"offset":"100%"}})],1)],1),_c('path',{attrs:{"stroke-width":"1","fill":"none","stroke":"url(#grad2)","d":`M ${_vm.width / 2 - 120},0 ${_vm.width / 2 - 110},35 Q ${_vm.width / 2 -
          107},42 ${_vm.width / 2 - 100},45 L ${_vm.width / 2 - 100},45 ${_vm.width / 2 +
          100},45 Q ${_vm.width / 2 + 107},42 ${_vm.width / 2 + 110},35 ${_vm.width / 2 +
          110},35 ${_vm.width / 2 + 120},0`}}),_c('defs',[_c('linearGradient',{attrs:{"id":"grad3","x1":"0%","y1":"0%","x2":"100%","y2":"0%"}},[_c('stop',{staticStyle:{"stop-color":"#187ACD","stop-opacity":"1"},attrs:{"offset":"0%"}}),_c('stop',{staticStyle:{"stop-color":"#22DBFD","stop-opacity":"1"},attrs:{"offset":"100%"}})],1)],1),_c('rect',{staticStyle:{"stroke-width":"0"},attrs:{"x":`${_vm.width / 2 - 40}`,"y":44,"rx":"2","ry":"2","width":"80","height":"3","fill":"url(#grad3)"}}),_c('g',[_c('path',{attrs:{"d":"M20,20 L121,20 L121,21 L20,21 Z","stroke-dasharray":"3,5","stroke":"url(#grad1)","fill":"none","stroke-width":"2"}}),_c('path',{attrs:{"d":"M20,27 L121,27 L121,28 L20,28 Z","stroke-dasharray":"3,5","stroke":"url(#grad1)","fill":"none","stroke-width":"2"}})]),_c('g',[_c('path',{attrs:{"transform":`translate(${_vm.width},40)rotate(180)`,"d":`M20,20 L121,20 L121,21 L20,21 Z`,"stroke-dasharray":"3,5","stroke":"url(#grad1)","fill":"none","stroke-width":"2"}}),_c('path',{attrs:{"transform":`translate(${_vm.width},55)rotate(180)`,"d":"M20,27 L121,27 L121,28 L20,28 Z","stroke-dasharray":"3,5","stroke":"url(#grad1)","fill":"none","stroke-width":"2"}})])]):_c('svg',{staticClass:"border",attrs:{"width":_vm.width,"height":_vm.height}},[_c('path',{attrs:{"d":"M20,50 L121,50 L121,51 L20,51 Z","stroke-dasharray":"3,5","stroke":"url(#grad1)","fill":"none","stroke-width":"2"}}),_c('path',{attrs:{"d":"M20,57 L121,57 L121,58 L20,58 Z","stroke-dasharray":"3,5","stroke":"url(#grad1)","fill":"none","stroke-width":"2"}})]),_vm._l((_vm.border),function(item){return _c('svg',{key:item,class:`${item} border`,attrs:{"width":"40px","height":"40px"}},[_c('polygon',{attrs:{"fill":"#21E7FF","points":"30,0 10,0 0,10 0,20 3,25 3, 12 12, 3 35, 3"}},[_c('animate',{attrs:{"attributeName":"fill","values":"#21E7FF;#2D77F3;#21E7FF","dur":"5s","begin":"0s","repeatCount":"indefinite"}})])])}),_c('div',{staticClass:"border-box-content"},[_vm._t("default")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }