import { onMounted, watchEffect, ref, nextTick } from 'vue';
import { debounce } from 'lodash';

export default function useAutoResize(dom) {
  // export default function useAutoResize(dom: any) {

  const width = ref(0);
  // const width = ref<number>(0)
  const height = ref(0);
  // const height = ref<number>(0)

  const autoResizeInit = () => {
    getDebounceInitWHFun();
    bindDomResizeCallback();
  };
  const initWH = () => {
    return new Promise(resolve => {
      // return new Promise<void>((resolve) => {
      nextTick(() => {
        width.value = dom.value ? dom.value.clientWidth : 0;
        height.value = dom.value ? dom.value.clientHeight : 0;
      });
      resolve();
    });
  };
  const getDebounceInitWHFun = debounce(initWH, 100);

  const bindDomResizeCallback = () => {
    window.addEventListener('resize', () => getDebounceInitWHFun());
  };

  watchEffect(bindDomResizeCallback);

  onMounted(autoResizeInit);

  return {
    width,
    height
  };
}
