<template>
  <div>
    <div class="table-title">
      <!-- <div title="加油站名称">加油站名称</div>
      <div title="加油机名称">加油机名称</div>
      <div title="巡检结果">巡检结果</div>
      <div title="巡检时间">巡检时间</div> -->

      <div v-for="item in tableHeader" :key="item" :title="item">
        {{ item }}
      </div>
    </div>
    <vue-seamless-scroll
      v-if="serialData.length > 0"
      :data="serialData"
      :class-option="classOption"
      class="seamless-wrap"
      :style="{ height: `${panelHeight}px` }"
    >
      <div>
        <div
          class="list-item"
          v-for="data in serialData"
          :key="data.create_time"
        >
          <div class="list-item__box">
            <div class="list-item__name">
              {{ data.site_name }}
            </div>
          </div>
          <div class="list-item__box">
            <div class="list-item__name">
              {{ data.tanker_name }}
            </div>
          </div>
          <div class="list-item__box">
            <div class="list-item__type">
              {{ data.result }}
            </div>
          </div>
          <div class="list-item__box">
            <time class="list-item__time">{{ data.time }}</time>
          </div>
        </div>
      </div>
    </vue-seamless-scroll>
    <div v-else style="color: #ccc; text-align: center">没有数据！</div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import BorderBoxVue from './BorderBox.vue';
import vueSeamlessScroll from 'vue-seamless-scroll';

export default defineComponent({
  components: {
    BorderBox: BorderBoxVue,
    vueSeamlessScroll
  },
  computed: {
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 0, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      };
    }
  },
  props: {
    serialData: {
      type: Array,
      default: []
    },
    tableHeader: {
      type: Array,
      default: []
    },
    panelHeight: {
      type: Number,
      default: 310
    }
  },
  setup() {}
});
</script>

<style lang="scss" scoped>
.table-title {
  height: 44px;
  width: calc(100% - 64px);
  color: #21e7ff;
  margin: 0 16px 0;
  display: flex;
  justify-content: space-between;
  line-height: 38px;
  padding: 0 12px;

  & > div {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:nth-child(1) {
      text-align: left;
      width: 30%;
    }

    &:nth-child(2) {
      width: 30%;
    }

    &:nth-child(3) {
      width: 15%;
    }

    &:nth-child(4) {
      width: 25%;
    }
  }
}

.seamless-wrap {
  width: calc(100% - 40px);
  height: calc(100% - 105px);
  overflow: hidden;
  margin: 0 16px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;

  .list-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 4px 12px;
    box-sizing: border-box;
    line-height: 25px;
    &__box {
      font-size: 12px;
      text-align: center;
      &:nth-child(1) {
        text-align: left;
        width: 30%;
      }
      &:nth-child(2) {
        // margin-top: 8px;
        width: 30%;
      }
      &:nth-child(3) {
        width: 15%;
        color: #f4ba19;
      }
      &:nth-child(4) {
        width: 25%;
      }
    }
    &__name {
      letter-spacing: 1px;
      font-size: 14px;
      line-height: 20px;
      // font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
