<template>
  <!-- <div class="full-screen" ref="el"> -->
  <div
    class="full-screen"
    ref="el"
    :style="`width: ${width}px; height: ${height}px;`"
  >
    <!-- <dv-full-screen-container class="full-screen"> -->
    <Headers
      class="full-screen-header"
      :style="{ height: headerHeight + 'px' }"
    />
    <el-row :gutter="0" class="full-screen-container">
      <el-col :span="4" v-for="item in basicDataLabel" :key="item.param_name">
        <!-- <border-box style="height: 90px" :data="item"> -->
        <dv-border-box-9>
          <div class="statistic">
            <!-- <div>加油站个数</div> -->
            <span class="box-title">{{ item.title }}</span>
            <div class="box-tank_data">
              <dv-decoration-3 style="width:160px;height:20px;" />
              <span>
                {{ basicData[item.param_name] }}
              </span>
            </div>
          </div>
        </dv-border-box-9>
        <!-- </border-box> -->
      </el-col>
      <el-col :span="8">
        <dv-border-box-11
          :color="['#00BA93']"
          :style="`height: ${panelHeight2 + panelHeight1 + 20}px`"
          title="油品价格趋势"
        >
          <!-- <Panel :height="panelHeight2" title="油品价格趋势"> -->
          <div
            class="echart-container"
            ref="echartLine"
            :style="{
              height: `${panelHeight2 + panelHeight1 - 45}px`,
              paddingTop: '48px'
            }"
          ></div>
          <!-- </Panel> -->
        </dv-border-box-11>
      </el-col>
      <el-col :span="16">
        <el-col :span="14">
          <dv-border-box-11
            :color="['#00BA93']"
            :style="`height: ${panelHeight1}px`"
            title="加油站分布图"
          >
            <!-- <Panel :height="panelHeight1" title="加油站分布地图"> -->
            <map-echarts
              :style="`height: ${panelHeight1 - 60}px;padding-top: 40px`"
            />
            <!-- </Panel> -->
          </dv-border-box-11>
        </el-col>
        <el-col :span="10">
          <dv-border-box-11
            :color="['#00BA93']"
            :style="`height: ${panelHeight1}px;`"
            title="加油机智能自检"
          >
            <!-- <Panel :height="panelHeight1" title="加油机智能自检"> -->
            <Scroll
              :style="{ paddingTop: '48px' }"
              :serialData="serialData"
              :tableHeader="tableHeader"
              :panelHeight="panelHeight2 - 70"
            ></Scroll>
            <!-- </Panel> -->
          </dv-border-box-11>
        </el-col>
        <el-col :span="6" v-if="false">
          <dv-border-box-11
            :color="['#00BA93']"
            :style="`height: ${panelHeight2}px`"
            title="加油机例行检查"
          >
            <!-- <Panel :height="panelHeight2" title="加油机例行检查"> -->
            <div
              class="echart-container"
              ref="echartTwoPie"
              :style="{ height: `${panelHeight2 - 65}px`, paddingTop: '48px' }"
            ></div>
            <!-- </Panel> -->
          </dv-border-box-11>
        </el-col>
        <el-col :span="14">
          <dv-border-box-11
            :color="['#00BA93']"
            :style="`height: ${panelHeight2}px`"
            title="每日出油量汇总"
          >
            <!-- <Panel :height="panelHeight2" title="每日出油量汇总"> -->
            <div
              class="echart-container"
              ref="echartBar"
              :style="{ height: `${panelHeight2 - 65}px`, paddingTop: '48px' }"
            ></div>
            <!-- </Panel> -->
          </dv-border-box-11>
        </el-col>
        <el-col :span="10">
          <dv-border-box-11
            :color="['#00BA93']"
            :style="`height: ${panelHeight2}px`"
            title="预警数量占比"
          >
            <div
              class="echart-container"
              ref="echartPie"
              :style="{ height: `${panelHeight2 - 65}px`, paddingTop: '40px' }"
            ></div>
          </dv-border-box-11>
        </el-col>
      </el-col>
    </el-row>
  </div>
  <!-- </dv-full-screen-container> -->
</template>
<script>
import { defineComponent, onMounted, ref, nextTick } from 'vue';
import { useWindowSize } from '@vueuse/core';

// import useCalcBoxSize from './components/useCalcBoxSize';
import BorderBox from './components/BorderBox.vue';
import Headers from './components/header.vue';
import Panel from './components/Panel.vue';
import Scroll from './components/scroll.vue';
import mapEcharts from './components/map_next.vue';
import vueSeamlessScroll from 'vue-seamless-scroll';

import {
  getDataVNewData,
  getDataVAlarmCount,
  getDataVHistoryPrice,
  getDataVTankerCheck
  // getDataVSiteMap
} from '@/api/home';

import echart from '../../components/Chart/utils';

import { basicDataParam } from './options/column';

// import mapEcharts from './components/map.vue';

// type DataVofProps = {
//     headerHeight: number;
//     gap: number;
//     panelHeight1: number;
//     panelHeight2: number;
//     statisticHeight: number;

// }

export default defineComponent({
  components: {
    BorderBox,
    Panel,
    Headers,
    vueSeamlessScroll,
    mapEcharts,
    Scroll
  },
  setup() {
    // const width = ref(window.screen.width);
    // const height = ref(document.body.clientHeight);
    const { width, height } = useWindowSize();
    // const { isFullscreen, enter, exit, toggle } = useFullscreen();

    // console.log(isFullscreen.value);
    // const scaleW = width / 1920;
    const scaleH = height.value / 1080;

    const gap = ref(16);
    const headerHeight = ref(85 * scaleH);
    const panelHeight1 = ref(432 * scaleH);
    const panelHeight2 = ref(400 * scaleH);
    const statisticHeight = ref(90 * scaleH);

    // const state = reactive({
    //   gap: 16,
    //   headerHeight: 85 * scaleH,
    //   panelHeight1: 432 * scaleH,
    //   panelHeight2: 400 * scaleH,
    //   statisticHeight: 90 * scaleH
    // });

    const basicDataLabel = ref(basicDataParam);

    const initDataV = () => {
      document.body.style = {
        background: '#030920',
        overflow: 'hidden'
      };
    };

    const echartPie = ref(null);
    const echartLine = ref(null);
    const echartTwoPie = ref(null);
    const echartBar = ref(null);
    const draw = (el, elRef, data) => {
      nextTick(() => {
        echart(el, elRef, data);
      });
    };

    let basicData = ref({
      site_count: 20,
      tanker_count: 23,
      tank_count: 12,
      change_count: 21,
      port_status: 8,
      lock_count: 17
    });

    let alarmChartConfig = ref({
      data: []
    });

    const basicDataFun = () => {
      // 基础数量
      getDataVNewData().then(res => {
        if (res.code === 0) {
          basicData.value = res.data;
        }
      });

      // 预警占比
      getDataVAlarmCount().then(res => {
        let { code, data } = res;
        if (code !== 0) {
          data = [];
        }
        alarmChartConfig.value.data = data;

        draw(
          echartPie.value,
          // 'plantPie',
          'plantPie',
          data.sort((a, b) => {
            return b.count - a.count;
          })
        );
      });

      // 油品价格趋势
      getDataVHistoryPrice().then(res => {
        const { code, data } = res;
        let trendData = {
          date: [],
          oilList: []
        };
        if (code === 0) {
          data.map(item => {
            trendData.date.push(item.date);
            for (let obj in item) {
              let _lIn = obj.lastIndexOf('#');
              let oil = obj.substring(0, _lIn + 1);
              if (_lIn !== -1 && trendData.oilList.indexOf(oil) === -1) {
                trendData.oilList.push(oil);
              }
            }
          });

          data.map(item => {
            trendData.oilList.forEach(obj => {
              if (!trendData[`${obj}data`]) trendData[`${obj}data`] = [];
              trendData[`${obj}data`].push(item[`${obj}price`] || '---');
            });
          });
        }
        draw(echartLine.value, 'plantLine', trendData);
      });

      // 加油机例行检查/智能自检/每日出油量汇总
      getDataVTankerCheck().then(res => {
        const { code, data } = res;
        if (code === 0) {
          serialData.value = data.smartcheck;

          let oilOut = {
            date: [],
            yw_Data: [],
            jk_Data: []
          };
          data.out_oil.map(item => {
            oilOut.date.push(item.dis_time);
            oilOut.yw_Data.push(item.yw_quantity);
            oilOut.jk_Data.push(item.jk_quantity);
          });

          draw(echartBar.value, 'plantBar', oilOut);
        }
      });
    };

    const tableHeader = ref([
      '加油站名称',
      '加油机名称',
      '巡检结果',
      '巡检时间'
    ]);
    let serialData = ref([]);

    onMounted(() => {
      initDataV();
      basicDataFun();
      window.addEventListener('resize', () => {
        const { height } = useWindowSize();
        const scale = height.value / 1080;

        headerHeight.value = 95 * scale;
        panelHeight1.value = 432 * scale;
        panelHeight2.value = 380 * scale;
        statisticHeight.value = 80 * scale;
      });
    });
    return {
      width,
      height,
      gap,
      headerHeight,
      panelHeight1,
      panelHeight2,
      statisticHeight,
      basicDataLabel,
      basicData,
      // mapEchartsDatas,
      echartPie,
      echartLine,
      echartTwoPie,
      echartBar,

      serialData,
      tableHeader
    };
  }
});
</script>
<style lang="scss" scoped>
.full-screen {
  position: relative;
  width: 100%;
  // height: 100%;
  background: url('../../assets/datav_bg.jpeg') no-repeat top center;
  background-size: cover;
  overflow: auto;
  // min-height: 1080px;
  filter: hue-rotate(315deg);

  &-header {
    position: fixed;
    width: 100%;
    height: 95px;
    margin-bottom: 15px;
  }

  &-container {
    position: absolute;
    top: 100px;
    padding: 0 8px;
    width: 100%;

    .el-col {
      padding: 6px;
    }
  }

  .tank_data {
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: #f4ba19;
    font-size: 25px;
    font-weight: bold;
  }
}

.table-title {
  height: 44px;
  width: calc(100% - 64px);
  color: #fff;
  margin: 0 16px 0;
  display: flex;
  justify-content: space-between;
  line-height: 38px;
  padding: 0 12px;

  & > div {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:nth-child(1) {
      text-align: left;
      width: 30%;
    }

    &:nth-child(2) {
      width: 30%;
    }

    &:nth-child(3) {
      width: 15%;
    }

    &:nth-child(4) {
      width: 25%;
    }
  }
}

.seamless-wrap {
  width: calc(100% - 40px);
  height: calc(100% - 105px);
  overflow: hidden;
  margin: 0 16px;
  font-size: 14px;
  font-family: Microsoft YaHei, serif;
  font-weight: 400;
  color: #ffffff;

  .list-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 4px 12px;
    box-sizing: border-box;
    line-height: 20px;

    &__box {
      font-size: 12px;
      text-align: center;

      &:nth-child(1) {
        text-align: left;
        width: 30%;
      }

      &:nth-child(2) {
        // margin-top: 8px;
        width: 30%;
      }

      &:nth-child(3) {
        width: 15%;
        color: #f4ba19;
      }

      &:nth-child(4) {
        width: 25%;
      }
    }

    &__name {
      letter-spacing: 1px;
      font-size: 14px;
      line-height: 20px;
      // font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.statistic {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // height: 90px;
  padding: 16px 24px;
  box-sizing: border-box;
}

.box-title {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 16px;
  color: #ffffff;
}

.box-tank_data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: right;
  font-size: 28px;
  font-weight: bold;
  background: linear-gradient(to bottom, #ffffff 0, #00ffff 80%);
  background-clip: text;
  color: transparent;
  text-shadow: 2px 3px 4px rgba(#000000, 0.4);
}
</style>
