<template>
  <div>
    <div v-if="timeDate" class="time" :style="['text-style']">
      <span class="date-span">{{ date | dateValue }}</span>
      <span class="time-span" :style="{ 'font-size': timeFontSize }">{{
        date | timeValue
      }}</span>
      <span class="day-span">{{ date | dayValue }}</span>
    </div>
    <span class="time" v-else :style="['text-style']">
      {{ date | formatDate }}
    </span>
  </div>
</template>

<script>
import { padDate } from '@/utils/utils';

let that;
export default {
  data() {
    return {
      date: Date.now(),
      dayList: ['一', '二', '三', '四', '五', '六', '日']
    };
  },
  props: {
    ['text-style']: [String, Object],
    timeDate: {
      type: Boolean,
      default: false
    },
    timeFontSize: {
      type: String,
      default: '18px'
    }
  },
  beforeCreate: function() {
    that = this;
  },
  mounted: function() {
    this.timer = setInterval(() => {
      this.date = Date.now(); //修改数据date
    }, 1000);
  },

  beforeDestroy: function() {
    if (this.timer) {
      clearInterval(this.timer); //在Vue实力销毁前，清除定时器
    }
  },

  filters: {
    formatDate(val) {
      const date = new Date(val);
      const y = padDate(date.getFullYear());
      const mth = padDate(date.getMonth() + 1);
      const day = padDate(date.getDate());
      const h = padDate(date.getHours());
      const m = padDate(date.getMinutes());
      const s = padDate(date.getSeconds());

      return `${y}年${mth}月${day}日 ${h}:${m}:${s}`;
    },
    timeValue(val) {
      const date = new Date(val);
      const h = padDate(date.getHours());
      const m = padDate(date.getMinutes());
      const s = padDate(date.getSeconds());

      return `${h}:${m}:${s}`;
    },
    dateValue(val) {
      const date = new Date(val);
      const y = padDate(date.getFullYear());
      const mth = padDate(date.getMonth() + 1);
      const day = padDate(date.getDate());
      return `${y}年${mth}月${day}日`;
    },
    dayValue() {
      const date = new Date();
      const day = date.getDay();
      return `星期${that.dayList[day - 1]}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.time {
  font-size: 18px;
  // line-height: 1.5;
  color: inherit;
  color: #21e7ff;

  span {
    margin-left: 16px;
  }
}

.time-span {
  font-family: 'HuXiaoBo-NanShenTi2.0';
}
</style>
