<template>
  <div>
    <div class="header">
      <div>
        <span class="line"></span>
        <div class="left">
          <Time :timeDate="true" />
        </div>
      </div>
      <div class="header__title">{{ $store.state.app.title }}</div>
      <div class="header__more">
        <span @click="$router.push('/home')" style="margin-right: 15px;"
          >燃油加油机智慧监管服务平台</span
        >
        <!-- <el-dropdown class="user">
          <span class="userinfo"
            ><i class="el-icon-user"></i>帐号：{{ username }}
          </span>
          <el-dropdown-menu
            slot="dropdown"
            class="cust-dropdown"
            style="width: 100px"
          >
            <div class="select-menu" @click="logout">退出登录</div>
          </el-dropdown-menu>
        </el-dropdown> -->

        <el-tooltip class="item" :content="`帐号：${username}`">
          <span @click="logout" style="vertical-align: bottom;">
            <Icon type="user" size="24px" />
            帐号：{{ username }}
          </span>
        </el-tooltip>

        <span
          @click="logout"
          title="退出"
          style="margin-left: 15px; vertical-align: middle"
        >
          <Icon type="logout" size="24px" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Time from '@/components/time';
import Icon from '@/components/Icon';

export default {
  data() {
    return {
      title: '燃油加油机智慧监管服务平台'
    };
  },
  components: {
    Time,
    Icon
  },
  computed: {
    username() {
      return this.$store.getters.departName;
    }
  },
  methods: {
    logout: () => {
      // const { $store, $confirm, $router, $message } = ctx.root;
      this.$confirm('确定要退出当前帐号吗？', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$store
            .dispatch('user/logout')
            .then(() => {
              this.$message({
                message: '成功退出！',
                type: 'warning'
              });
              this.$router.replace('/login');
            })
            .catch(err => {
              this.$message({
                message: err.msg,
                type: 'danger'
              });
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消当前操作！'
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #21e7ff;
  line-height: 60px;

  & > div {
    width: 33%;
  }

  &__title {
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    background-image: -webkit-linear-gradient(bottom, #2d77f3, #21e7ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
  }

  &__more {
    text-align: right;
    padding-right: 16px;
    cursor: pointer;
  }
  .userinfo {
    color: #21e7ff;
    font-size: 16px;
  }
}

.cust-dropdown {
  border: 0;
  padding: 0;
}
.select-menu {
  background: #21e7ff;
  color: #fff;
  border: 0;
  width: 100px;
  text-align: center;
  font-size: 14px;
  padding: 8px 0;
  border-radius: 4px;
  letter-spacing: 1px;
  user-select: none;
  cursor: pointer;
}
</style>
