<template>
  <div class="border-box" ref="borderBoxNode">
    <svg :width="width" :height="height" class="border">
      <defs>
        <linearGradient
          id="borderLineGradient"
          x1="0%"
          x2="100%"
          y1="0%"
          y2="0%"
          gradientTransform="rotate(45)"
        >
          <stop offset="5%" stop-color="#21E7FF" />
          <stop offset="95%" stop-color="#2D77F3" />
        </linearGradient>
      </defs>
      <path
        :d="
          `M 20,2 L 20, 2 ${width - 22}, 2 ${width - 12}, 2 ${width -
            2}, 12 ${width - 2}, ${height - 12}
          ${width - 12}, ${height - 2} 12, ${height - 2} 2, ${height -
            12} 2, 12 12, 2 Z
        `
        "
        fill="none"
        stroke="url(#borderLineGradient)"
        stroke-width="1px"
      />

      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop
            offset="0%"
            style="stop-color:rgba(33, 231, 255, .5);stop-opacity:1"
          />
          <stop
            offset="100%"
            style="stop-color:rgba(33, 231, 255, .1);stop-opacity:1"
          />
        </linearGradient>
      </defs>
    </svg>

    <svg v-if="ifPanel" :width="width" :height="height" class="border">
      <defs>
        <linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style="stop-color:#21E7FF;stop-opacity:1" />
          <stop offset="50%" style="stop-color:#21E7FF;stop-opacity:1" />
          <stop offset="100%" style="stop-color:#2D77F3;stop-opacity:1" />
        </linearGradient>
      </defs>
      <path
        stroke-width="1"
        fill="none"
        stroke="url(#grad2)"
        :d="
          `M ${width / 2 - 120},0 ${width / 2 - 110},35 Q ${width / 2 -
            107},42 ${width / 2 - 100},45 L ${width / 2 - 100},45 ${width / 2 +
            100},45 Q ${width / 2 + 107},42 ${width / 2 + 110},35 ${width / 2 +
            110},35 ${width / 2 + 120},0`
        "
      />

      <defs>
        <linearGradient id="grad3" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style="stop-color:#187ACD;stop-opacity:1" />
          <stop offset="100%" style="stop-color:#22DBFD;stop-opacity:1" />
        </linearGradient>
      </defs>
      <rect
        :x="`${width / 2 - 40}`"
        :y="44"
        rx="2"
        ry="2"
        width="80"
        height="3"
        fill="url(#grad3)"
        style="stroke-width:0"
      />

      <g>
        <path
          d="M20,20 L121,20 L121,21 L20,21 Z"
          stroke-dasharray="3,5"
          stroke="url(#grad1)"
          fill="none"
          stroke-width="2"
        />
        <path
          d="M20,27 L121,27 L121,28 L20,28 Z"
          stroke-dasharray="3,5"
          stroke="url(#grad1)"
          fill="none"
          stroke-width="2"
        />
      </g>
      <g>
        <path
          :transform="`translate(${width},40)rotate(180)`"
          :d="`M20,20 L121,20 L121,21 L20,21 Z`"
          stroke-dasharray="3,5"
          stroke="url(#grad1)"
          fill="none"
          stroke-width="2"
        />
        <path
          :transform="`translate(${width},55)rotate(180)`"
          d="M20,27 L121,27 L121,28 L20,28 Z"
          stroke-dasharray="3,5"
          stroke="url(#grad1)"
          fill="none"
          stroke-width="2"
        />
      </g>
    </svg>
    <svg v-else :width="width" :height="height" class="border">
      <!-- <text x="20" y="30" fill="#21E7FF">{{ item.title }}</text> -->
      <!-- 虚线 -->
      <path
        d="M20,50 L121,50 L121,51 L20,51 Z"
        stroke-dasharray="3,5"
        stroke="url(#grad1)"
        fill="none"
        stroke-width="2"
      />
      <path
        d="M20,57 L121,57 L121,58 L20,58 Z"
        stroke-dasharray="3,5"
        stroke="url(#grad1)"
        fill="none"
        stroke-width="2"
      />
    </svg>

    <svg
      width="40px"
      height="40px"
      v-for="item in border"
      :key="item"
      :class="`${item} border`"
    >
      <polygon
        fill="#21E7FF"
        points="30,0 10,0 0,10 0,20 3,25 3, 12 12, 3 35, 3"
      >
        <animate
          attributeName="fill"
          values="#21E7FF;#2D77F3;#21E7FF"
          dur="5s"
          begin="0s"
          repeatCount="indefinite"
        />
      </polygon>
    </svg>

    <div class="border-box-content">
      <slot />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, watchEffect } from 'vue';
import useAutoResize from './useAutoResize';

export default defineComponent({
  props: {
    ifPanel: {
      type: Boolean
    },
    data: {
      type: Object
    }
  },
  setup() {
    const borderBoxNode = ref(null);
    const { width, height } = useAutoResize(borderBoxNode);

    watchEffect(() => {
      // const { width, height } = useAutoResize(borderBoxNode);
    });

    return {
      width,
      height,
      borderBoxNode
    };
  },
  data() {
    return {
      border: ['left-top', 'right-top', 'left-bottom', 'right-bottom']
    };
  }
});
</script>

<style lang="scss" scoped>
.border-box {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  box-shadow: inset 0px 0px 60px 0px rgba(0, 6, 255, 0.1);

  .border {
    position: absolute;
    display: block;
  }

  .right-top {
    right: 0px;
    transform: rotateY(180deg);
  }

  .left-bottom {
    bottom: 0px;
    transform: rotateX(180deg);
  }

  .right-bottom {
    right: 0px;
    bottom: 0px;
    transform: rotate(180deg);
  }

  .border-box-content {
    position: relative;
    width: 100%;
    height: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .title {
      position: absolute;
      top: 16px;
      left: 16px;
      color: #fff;
      font-weight: bolder;
      letter-spacing: 2px;
    }
  }
}
</style>
