<template>
  <border-box :style="`height: ${height}px`" :ifPanel="true">
    <div class="panel-title">{{ title }}</div>
    <slot />
  </border-box>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import BorderBoxVue from './BorderBox.vue';

export default defineComponent({
  components: {
    BorderBox: BorderBoxVue
  },
  props: {
    height: {
      type: Number,
      default: 100
    },
    title: {
      type: String,
      default: ''
    }
  },
  setup() {}
});
</script>

<style scoped>
.panel-title {
  /* color: #21e7ff; */
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 2px;
  text-align: center;
  line-height: 40px;
  padding-top: 4px;
  margin-bottom: 10px;
}
</style>
