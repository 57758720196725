export const serial = [
  {
    id: 1,
    site_name: '加油站1',
    tanker_name: '1号加油机',
    status: 1,
    time: '2021-05-22 12:00:11'
  },
  {
    id: 2,
    site_name: '加油站2',
    tanker_name: '2号加油机',
    status: 0,
    time: '2021-05-22 12:00:11'
  },
  {
    id: 3,
    site_name: '加油站3',
    tanker_name: '3号加油机',
    status: 1,
    time: '2021-05-22 12:00:11'
  },
  {
    id: 4,
    site_name: '加油站4',
    tanker_name: '4号加油机',
    status: 1,
    time: '2021-05-22 12:00:11'
  },
  {
    id: 5,
    site_name: '加油站5',
    tanker_name: '5号加油机',
    status: 1,
    time: '2021-05-22 12:00:11'
  }
];

export const basicDataParam = [
  { param_name: 'site_count', title: '加油站数量(个)' },
  { param_name: 'tanker_count', title: '加油机数量(个)' },
  { param_name: 'tank_count', title: '加油枪数量(个)' },
  // { param_name: 'tank_count', title: '油罐数量(个)' },
  { param_name: 'change_count', title: '计控主板变更(个)' },
  { param_name: 'port_status', title: '防作弊未开启(个)' },
  { param_name: 'lock_count', title: '超差锁机(个)' }
];

export const mapEchartsLabel = {
  data: [
    //地图数据
    { name: '山东省', value: '12000' },
    { name: '临沂市', value: '4000' },
    { name: '青岛市', value: '4000' },
    { name: '济南市', value: '4000' },
    { name: '兰山区', value: '1500' },
    { name: '河东区', value: '1500' },
    { name: '罗庄区', value: '1000' },
    { name: '示范区', value: '1000' },
    { name: '经济技术开发区', value: '1000' },
    { name: '鹤壁市', value: '3000' },
    { name: '淇滨区', value: '1000' }
  ],
  custom: [
    //地图自定义轮廓
    // {
    //   fatherCode:410600,
    //   name:'鹤壁市',  //自定义地图的父级
    //   children:[
    //     {
    //       name:'淇滨区',
    //       custom:hebiqibin  //自定义地图的json数据
    //     },
    //     {
    //       name:'经济技术开发区',
    //       custom:hebiArea
    //     },
    //     {
    //       name:'示范区',
    //       custom:hebiOpen
    //     },
    //     {
    //       name:'淇县',
    //       custom:hebiqixian
    //     },
    //     {
    //       name:'浚县',
    //       custom:hebijunxian
    //     }
    //   ]
    // }
  ],
  userCode: {
    //登录账号的adcode
    adcode: 100000,
    name: '全国'
  },
  lableTitle: [
    {
      //地图右下角的标签
      title: '售油总量（吨）',
      value: '0'
    },
    {
      title: '进油总量（吨）',
      value: '00'
    }
  ]
};
